import React from "react";

function CancelConfirmation() {

  return (
    <div className="instruction_main_container">
      <div className="pre-consultation-page">
        <div className="content_page">
          <h2>Booking Canceled</h2>
          <span>
            We’re sorry to see you go!<br></br> Your appointment has been canceled.
            You can rebook at any time through our scheduling page.
          </span>
        </div>
      </div>
    </div>
  );
}
export default CancelConfirmation;
