import React from 'react';
import "./../../Assets/css/Booking.css";
import useBookingApp from '../../hooks/useBookingApp';
import AsyncRendererRouter from '../../Components/AsyncRendererRouter/AsyncRendererRouter';
import { useLoaderData } from "react-router-dom";
import CustomLoader from '../../Components/controls/CustomLoader';

export default function BookingPage() {
    const { templateSectionState } = useBookingApp();
    const { pharmacyId, isServicesLoading } = templateSectionState;

    const { appData } = useLoaderData();

    return (
        <AsyncRendererRouter
            isLoading={isServicesLoading}
            resolve={appData}
            loadingElement={
                <div className="custom_loader">
                    <CustomLoader />
                </div>
            }
        >
            <div className="booking_wrapper">
                <div className='booking_content_wrapper'>
                    <h1>Book an Appointment</h1>
                    <iframe
                        src={`/book-now/${pharmacyId}`}
                        title="Booking Iframe"
                        width="60%"
                        height="800px"
                        sandbox="allow-scripts allow-same-origin allow-forms"
                    >
                    </iframe>
                </div>
            </div>
        </AsyncRendererRouter >
    );
}
