import React, { useState, useEffect } from "react";
import "./../../Assets/css/Booking.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { format, addDays, subDays, isToday } from "date-fns";
import useBookingApp from "../../hooks/useBookingApp";
import CustomLoader from "../../Components/controls/CustomLoader";
import AsyncRendererRouter from "../../Components/AsyncRendererRouter/AsyncRendererRouter";
import { useLoaderData, useNavigate } from "react-router-dom";
import {
  setInfectionServayData,
  setSelectedSlot,
  setIsCalendarVisible,
  setPractitioner,
  setIsPractionerVisible,
  setIsBack,
  setSelectedBookingDate,
  setSelectedBookingTime,
  setSelectedBookingSlot,
  GetPharmacyBookingById,
} from "../../dispatchers/templateSectionDispatchers";
import { setSelectedPatientDateTime } from "../../dispatchers/consentDispatchers";
import Login from "../login/login";
import ReshceduleBookingTabs from "./ReshceduleBookingTabs";

export default function CustomIframe() {
  const { consentState, templateSectionState } = useBookingApp();
  const {
    infectionServay,
    PrivateServices,
    isBookingLoading,
    pharmacyBookingData,
    isCalendarVisible,
    practitioner,
    isPractionerVisible,
    isBack,
    pharmacyId,
  } = templateSectionState;
  const { isPatientAuthenticated, currentServiceId } = consentState || {};
  const [currentDate, setCurrentDate] = useState(new Date());
  const [loadingSlots, setLoadingSlots] = useState(false);
  const getFormattedDate = (date) => format(date, "EEE MMM d");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 400);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 400);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Bind clinician names to the dropdown and add "All Practitioners"
  const uniquePractitioners = [
    "All Practitioners",
    ...new Set(
      pharmacyBookingData?.availableBooking?.map(
        (booking) => booking.clinicianName
      )
    ),
  ];

  // Generate the current day and next two days
  const days = [currentDate, addDays(currentDate, 1), addDays(currentDate, 2)];

  const handlePrevious = () => {
    if (!isToday(currentDate)) {
      setCurrentDate((prevDate) => subDays(prevDate, 1));
    }
  };

  const handleNext = () => {
    setCurrentDate((prevDate) => addDays(prevDate, 1));
  };

  // Function to filter bookings for a specific date and selected practitioner
  const filterBookingsByDate = (date) => {
    if (practitioner !== "All Practitioners") {
      return pharmacyBookingData?.availableBooking?.filter(
        (booking) =>
          format(new Date(booking.bookingDate), "yyyy-MM-dd") ===
            format(date, "yyyy-MM-dd") && booking.clinicianName === practitioner
      );
    } else {
      const uniqueSlots = [];
      return pharmacyBookingData?.availableBooking?.filter((booking) => {
        const isSameDate =
          format(new Date(booking.bookingDate), "yyyy-MM-dd") ===
          format(date, "yyyy-MM-dd");

        const isNotBooked = booking.status === 0;

        const isDuplicate = uniqueSlots.includes(booking.bookingTime);

        if (isSameDate && isNotBooked && !isDuplicate) {
          uniqueSlots.push(booking.bookingTime);
          return true;
        }

        return false;
      });
    }
  };

  const handlePractitionerChange = (event) => {
    setPractitioner(event.target.value);
    setLoadingSlots(true);
    setIsCalendarVisible(true);

    setTimeout(() => {
      setLoadingSlots(false);
    }, 1000);
  };

  const handleChange = async (event) => {
    const selectedService = event?.target?.value;

    try {
      setLoadingSlots(true);
      setIsCalendarVisible(true);
      setInfectionServayData(selectedService);
      setIsPractionerVisible(true);
      await GetPharmacyBookingById({
        pharmacyId: pharmacyId,
        serviceID: selectedService?._id,
      });
    } catch (error) {
      console.error("Error fetching booking data:", error);
    } finally {
      setLoadingSlots(false);
    }
  };

  const handleBookNow = (selectedBooking) => {
    const updatedBooking = { ...selectedBooking, status: 1 };
    setSelectedBookingSlot(updatedBooking);

    if (practitioner === "All Practitioners") {
      setPractitioner(selectedBooking.clinicianName);
    }
    // Find the booking that matches the selected slot for the selected clinician
    const selectedPractitionerBooking =
      pharmacyBookingData?.availableBooking?.find(
        (booking) =>
          booking.clinicianName === selectedBooking.clinicianName &&
          booking.bookingTime === selectedBooking?.bookingTime &&
          booking.bookingDate === selectedBooking?.bookingDate
      );

    if (selectedPractitionerBooking) {
      // Update the status of the selected slot for that clinician
      const updatedPharmacyBookingData = { ...pharmacyBookingData };

      const updatedBookings = updatedPharmacyBookingData.availableBooking.map(
        (booking) => {
          // Handle the case where patient is authenticated and patientEmail matches
          if (
            isPatientAuthenticated &&
            booking.clinicalServiceId === currentServiceId
          ) {
            return { ...booking, status: 0, clinicalServiceId: "" }; // Reset the slot
          }

          // Handle the case where the selected slot is being booked
          if (
            booking.bookingTime === selectedPractitionerBooking.bookingTime &&
            booking.clinicianName ===
              selectedPractitionerBooking.clinicianName &&
            booking.bookingDate === selectedPractitionerBooking.bookingDate
          ) {
            return {
              ...booking,
              status: 1,
              clinicalServiceId: currentServiceId,
            }; // Mark the slot as booked
          }

          return booking; // Keep other slots unchanged
        }
      );

      // Store the updated booking data and update the state
      updatedPharmacyBookingData.availableBooking = updatedBookings;
      setSelectedSlot(updatedPharmacyBookingData);
      setSelectedBookingDate(selectedPractitionerBooking.bookingDate);
      setSelectedBookingTime(selectedPractitionerBooking.bookingTime);
      const bookingDate = selectedPractitionerBooking?.bookingDate; // e.g., "2024-11-28T00:00:00Z"
      const bookingTime = selectedPractitionerBooking?.bookingTime; // e.g., "09:00"
      const dateTimeString = `${bookingDate.split("T")[0]}T${bookingTime}:00Z`;
      const dateTime = new Date(dateTimeString);
      setSelectedPatientDateTime(dateTime);
      isPatientAuthenticated
        ? navigate("/GeneralInstructions")
        : setIsBack(false);
    }
  };

  const isPrevDisabled = isToday(currentDate);
  const { appData } = useLoaderData();

  return (
    <AsyncRendererRouter
      isLoading={isBookingLoading}
      resolve={appData}
      loadingElement={
        <div className="custom_loader">
          <CustomLoader />
        </div>
      }
    >
      {isBack ? (
        isPatientAuthenticated ? (
          <div className="appointment-scheduler booking_calendar_controll_wrapper">
            <ReshceduleBookingTabs />
          </div>
        ) : (
          <div className="appointment-scheduler">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select a service
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={infectionServay}
                  label="Select a service"
                  onChange={handleChange}
                >
                  {PrivateServices && PrivateServices.length > 0 ? (
                    PrivateServices.map((service) => (
                      <MenuItem key={service._id} value={service}>
                        {service.serviceName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No services available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
            {isPractionerVisible && (
              <Box sx={{ minWidth: 120, marginTop: "16px" }}>
                <FormControl fullWidth>
                  <InputLabel id="practitioner-select-label">
                    Select a practitioner
                  </InputLabel>
                  <Select
                    labelId="practitioner-select-label"
                    id="practitioner-select"
                    value={practitioner}
                    label="Select a practitioner"
                    onChange={handlePractitionerChange}
                  >
                    {uniquePractitioners?.map((clinician, index) => (
                      <MenuItem key={index} value={clinician}>
                        {clinician}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            {isCalendarVisible && (
              <div className="calendar">
                <div className="calendar-header">
                  <div className="calendar-ui">
                    <button
                      className={`nav-button ${
                        isPrevDisabled ? "disabled" : ""
                      }`}
                      onClick={handlePrevious}
                      disabled={isPrevDisabled}
                    >
                      <i
                        className={`fas fa-chevron-left ${
                          isPrevDisabled ? "disabled-icon" : ""
                        }`}
                      ></i>
                    </button>
                    {!isMobile ? (
                      <div className="days">
                        {days.map((day, index) => (
                          <div key={index} className="day">
                            <div>{getFormattedDate(day).split(" ")[0]}</div>{" "}
                            {/* Day abbreviation */}
                            <div>
                              {getFormattedDate(day).split(" ")[1]}{" "}
                              {getFormattedDate(day).split(" ")[2]}
                            </div>{" "}
                            {/* Month and Date */}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="days">
                        <div className="day">
                          <div>
                            {getFormattedDate(currentDate).split(" ")[0]}
                          </div>{" "}
                          {/* Day abbreviation */}
                          <div>
                            {getFormattedDate(currentDate).split(" ")[1]}{" "}
                            {getFormattedDate(currentDate).split(" ")[2]}
                          </div>{" "}
                          {/* Month and Date */}
                        </div>
                      </div>
                    )}

                    <button className="nav-button" onClick={handleNext}>
                      <i className="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>

                {loadingSlots ? (
                  <div className="loading-dots">
                    <span className="loading">.</span>
                    <span className="loading">.</span>
                    <span className="loading">.</span>
                  </div>
                ) : (
                  <div className="calendar-slots">
                    {days?.map((day, index) => {
                      const bookingsForDay = filterBookingsByDate(day);

                      return (
                        <div key={index} className="slot-group">
                          {bookingsForDay?.length > 0 ? (
                            bookingsForDay?.map((booking, idx) =>
                              booking?.status === 0 ? (
                                <button
                                  key={idx}
                                  className="slot"
                                  onClick={() => handleBookNow(booking)}
                                >
                                  {booking?.bookingTime}
                                </button>
                              ) : (
                                <button
                                  key={idx}
                                  className="not_available_slot"
                                  disabled
                                >
                                  {booking?.bookingTime}
                                </button>
                              )
                            )
                          ) : (
                            <button className="not_available_slot" disabled>
                              —
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </div>
        )
      ) : (
        <Login />
      )}
    </AsyncRendererRouter>
  );
}
