import React, { useState, useEffect } from "react";
import useBookingApp from "../../hooks/useBookingApp";
import { useNavigate } from "react-router-dom";
import {
  setBookingTemplate,
  saveTemplate,
} from "../../dispatchers/templateSectionDispatchers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "react-phone-input-2/lib/style.css";
import { Autocomplete, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import moment from "moment";
import countries from "../../services/countries.json";

function ReshceduleBooking() {
  const { templateSectionState, consentState } = useBookingApp();
  const {
    bookingTemplate,
    travelQuestion,
  } = templateSectionState;
  const { rescheduleBookingModel, currentServiceId } =
    consentState || {};
  const [inputValues, setInputValues] = useState(bookingTemplate);
  const navigate = useNavigate();

  useEffect(() => {
    setInputValues(bookingTemplate);
  }, [bookingTemplate]);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  function showTextBox(Title, control) {
    if (Title === "Medical History") {
      const template = inputValues?.find(
        (item) => item?.sectionTitle === Title
      );
      const userControl = template?.userControls?.find(
        (item) => item?.userControlId === control?.parentUserControlId
      );
      if (userControl?.type === 5 && userControl?.value === "false") {
        return true;
      } else {
        return false;
      }
    }
  }
  function resetTextBoxControls() {
    const updatedTemplate = inputValues?.map((section) => ({
      ...section,
      userControls: section?.userControls?.map((control) => ({ ...control })),
    }));

    const medicalHistorySection = updatedTemplate?.find(
      (section) => section?.sectionTitle === "Medical History"
    );

    medicalHistorySection?.userControls?.forEach((control) => {
      const parentControl = medicalHistorySection?.userControls?.find(
        (item) => item?.userControlId === control?.parentUserControlId
      );
      if (parentControl?.type === 5 && parentControl?.value === "false") {
        control.value = "";
      }
    });

    setInputValues(updatedTemplate);
    return updatedTemplate;
  }

  const handleFormChange = (index, event, type, sectionTitle) => {
    let value;
    if (type === 0) {
      value = event?.target?.value;
    } else if (type === 3) {
      value = event;
    } else if (type === 5) {
      value = event?.target?.checked === true ? "true" : "false";
    }

    const updatedTemplate = inputValues?.map((section) => ({
      ...section,
      userControls: section.userControls.map((control) => ({ ...control })),
    }));

    const section = updatedTemplate.find(
      (section) => section.sectionTitle === sectionTitle
    );
    if (section) {
      if (section.userControls[index]) {
        section.userControls[index].value = value;
        setInputValues(updatedTemplate);
      }
    }
  };

  function handleSaveTemplate() {
    const updatedInputValues = resetTextBoxControls();
    const templateModel = {
      ClinicalServiceId: currentServiceId,
      TemplateSections: updatedInputValues,
    };
    setBookingTemplate(inputValues);
    saveTemplate(templateModel).then((data) => {
      navigate(`/travelDetailsSummary`);
    });
  }

  const formatBookingDateWithOrdinal = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    // Get ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${date.toLocaleDateString("en-US", {
      weekday: "long",
    })}, ${day}${getOrdinalSuffix(day)} ${date.toLocaleDateString("en-US", {
      month: "long",
    })}`;
  };

  return (
    <div className="user_details_main_wrapper">
      <div className="confirmation_main_wrapper user_control_wrapper">
        <h4>Personal details</h4>
        <div className="content_title">
          <span>Appointment type:</span>
          <span>{rescheduleBookingModel[0]?.appointmentType}</span>
        </div>
        <div className="content_title">
          <span>Booking Date:</span>
          <span>
            {`${formatBookingDateWithOrdinal(
              rescheduleBookingModel[0]?.dateOfIntervention
            ) || ""
              }`}
          </span>
        </div>
        <div className="content_title">
          <span>Booking Time:</span>
          <span>
            {
              new Date(rescheduleBookingModel[0]?.dateOfIntervention)
                .toLocaleTimeString("en-US", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: false, // Use 24-hour format
                })
            }

          </span>
        </div>
        <div className="content_title">
          <span>Patient:</span>
          <span>{`${rescheduleBookingModel[0]?.patientName || ""}`}</span>
        </div>
        <div className="content_title">
          <span>Location:</span>
          <span>{`${rescheduleBookingModel[0]?.pharmacyName || ""}`}</span>
        </div>
      </div>
      {inputValues?.map((section, index) => {
        return (
          <div
            className="form-group user_control_wrapper"
            key={index}
          >
            <h4>{section?.sectionTitle}</h4>
            {section?.userControls?.map((control, index) => {
              if (
                control.type === 0 &&
                !showTextBox(section?.sectionTitle, control)
              ) {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={
                        control.inline === true ? "inline-not" : "inline"
                      }
                    >
                      <label
                        htmlFor={control?.name}
                        className="user_controls_label"
                      >
                        {control?.label}
                      </label>
                      <div className="template_control">
                        {control?.label === travelQuestion ? (
                          <Autocomplete
                            multiple
                            id={control?.name}
                            options={countries}
                            getOptionLabel={(option) => option.name}
                            value={
                              control?.value
                                ? control?.value
                                  .split(",")
                                  .map((val) =>
                                    countries.find(
                                      (country) => country?.value === val
                                    )
                                  )
                                : []
                            }
                            onChange={(event, newValue) => {
                              const selectedValues = newValue.map(
                                (country) => country?.value
                              );
                              handleFormChange(
                                index,
                                { target: { value: selectedValues.join(",") } },
                                control?.type,
                                section?.sectionTitle
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                required={control?.required}
                                fullWidth
                              />
                            )}
                            filterSelectedOptions
                          />
                        ) : (
                          <TextField
                            id={control?.name}
                            variant="outlined"
                            error={control?.required && !control?.value}
                            required={control?.required}
                            fullWidth
                            value={control?.value || ""}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                control?.type,
                                section?.sectionTitle
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              if (control.type === 5) {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={
                        control.inline === true ? "inline-not" : "inline"
                      }
                    >
                      <div
                        style={{ border: "#00ca69" }}
                        className="switch-content-reports-outer toggle-only"
                      >
                        <label className="user_controls_label antSwitchLabel">
                          {control?.label}
                        </label>
                        <div>
                          <AntSwitch
                            inputProps={{ "aria-label": "ant design" }}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                control?.type,
                                section?.sectionTitle
                              )
                            }
                            checked={
                              control.value === ""
                                ? false
                                : control.value === "false"
                                  ? false
                                  : control.value
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
              if (control.type === 3) {
                return (
                  <React.Fragment key={index}>
                    <div
                      className={
                        control.inline === true ? "inline-not" : "inline"
                      }
                    >
                      <label
                        htmlFor={control?.name}
                        className="user_controls_label"
                      >
                        {control?.label}
                      </label>
                      <div className="template_control">
                        <LocalizationProvider
                          dateAdapter={AdapterMoment}
                          adapterLocale="en-gb"
                        >
                          <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                              required={control?.required}
                              value={
                                control?.value ? moment(control?.value) : null
                              }
                              onChange={(event) =>
                                handleFormChange(
                                  index,
                                  event,
                                  control?.type,
                                  section?.sectionTitle
                                )
                              }
                              renderInput={(params) => (
                                <TextField {...params} fullWidth />
                              )}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
            })}
          </div>
        );
      })}
      <div className="booking_details_footer">
        <button
          type="submit"
          className="nhsuk-button"
          onClick={handleSaveTemplate}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
export default ReshceduleBooking;
