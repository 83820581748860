import React from "react";
import useBookingApp from "../../hooks/useBookingApp";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";

function TravelDetailsSummary() {
  const { templateSectionState } = useBookingApp();
  const { bookingTemplate } = templateSectionState;

  // Preprocess the data to extract necessary fields
  const travelDetails = {};
  bookingTemplate.forEach((section) => {
    section?.userControls.forEach((control) => {
      const sanitizedControlName = control?.name.replace(/[^\w]/g, "");
      travelDetails[sanitizedControlName] = control?.value || "Not Provided";
    });
  });
  // Format the date dynamically
  const formatBookingDateWithOrdinal = (dateString) => {
    if (!dateString) return "Not Provided";

    const date = new Date(dateString);
    const day = date.getDate();

    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${date.toLocaleDateString("en-US", {
      weekday: "long",
    })}, ${day}${getOrdinalSuffix(day)} ${date.toLocaleDateString("en-US", {
      month: "long",
    })}`;
  };

  return (
    <div className="instruction_main_container">
      <div className="pre-consultation-page">
        <div className="travel-details-summary-wrapper content_page">
          <h2>Travel Details Summary</h2>
          <div className="confirmation_main_wrapper">
            <div className="content_title">
              <span>Travelling to:</span>
              <span className="countries-list">
                {travelDetails?.WhereAreYouTravellingTo}
              </span>
            </div>
            <div className="content_title">
              <span>Travelling date:</span>
              <span>
                {formatBookingDateWithOrdinal(
                  travelDetails?.WhenAreYouTravelling
                )}
              </span>
            </div>
            <div className="content_title">
              <span>Travel duration:</span>
              <span>
                <span>
                  {travelDetails?.HowLongAreYouAwayFor || "Not provided"}
                </span>
              </span>
            </div>
            <div className="content_title">
              <span>Travel type:</span>
              <span>
                {
                  travelDetails?.AreYouTravellingDirectOrAreYouStoppingOffAnywhereElse
                }
              </span>
            </div>
            <div className="content_title">
              <span>Vaccination History:</span>
              <span>
                {
                  travelDetails?.PleaseListAllVaccinesPreviouslyAdminsteredToYouInTheLast10YearsAlternativelyPleaseBringaPrintOutOfYourVaccinationHistoryWithYouToYourAppointment
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TravelDetailsSummary;
