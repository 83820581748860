import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import useBookingApp from "../../hooks/useBookingApp";
import ConfirmationAccordian from "../NmsConsent/components/ConfirmationAccordian";
import NHSLogo from "../../Assets/nhsLogo.jpg";
import {
  authenticatePatient,
  getPharmacyServiceSettings,
  setIsPatientAuthenticated,
  setPatientConfirmationEmail,
  setRescheduleBookingModel,
} from "../../dispatchers/consentDispatchers";
import { getTemplateByServiceId } from "../../dispatchers/templateSectionDispatchers";
import { showError } from "../../Components/ToastedMessage/ToastedMessage";

function UserAuthenticationForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [credentials, setCredentials] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const navigate = useNavigate();
  const { consentState } = useBookingApp();
  const { currentServiceId, serviceId } = consentState || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      surname: "",
      dateOfBirth: null,
      activationCode: "",
    },
    validationSchema: Yup.object().shape({
      surname: Yup.string().required("Surname is required"),
      dateOfBirth: Yup.date().required("Date of birth is required"),
      activationCode: Yup.string().required("Activation Code is required"),
    }),
    onSubmit: (values) => {
      setLoadingSlots(true);

      const { surname, email, dateOfBirth, activationCode } = values;
      setPatientConfirmationEmail(email);
      const parsedDateOfBirth = new Date(dateOfBirth);
      // parsedDateOfBirth.setDate(parsedDateOfBirth.getDate() + 1);

      const userCredentials = {
        surname: surname,
        dob: parsedDateOfBirth,
        activationCode: activationCode,
      };

      authenticatePatient({
        obj: userCredentials,
        clinicalServiceID: currentServiceId,
      }).then((data) => {
        const { isAuthenticated, sMSSendDTO } = data || {};
        setIsPatientAuthenticated(isAuthenticated);
        setLoadingSlots(false);
        if (isAuthenticated) {
          setRescheduleBookingModel(sMSSendDTO);
          const pharmacyId = sMSSendDTO[0]?.pharmacyID;
          const serviceID = sMSSendDTO[0]?.serviceID;
          getTemplateByServiceId({
            pharmacyId: pharmacyId,
            serviceId: serviceId,
            clinicalServiceId: currentServiceId,
          })
            .then(() => {
              getPharmacyServiceSettings(pharmacyId, serviceID);
              navigate(`/book-now/${pharmacyId}`);
            })
            .catch((error) => {
              showError("Template not found.", true, false);
            });
        } else {
          setCredentials("Credentials did not match!");
          setIsValid(true);
        }
        setLoadingSlots(false);
      });
    },
  });

  return (
    <div className="user-confirmation">
      <div className="user-confirmation-form">
        <div className="nhsuk-width-container ">
          <div className="nhsuk-main-wrapper nhsuk-main-wrapper--s">
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <div className="nhsuk-form-group">
                  <div className="nhsuk-fieldset">
                    <h2>Confirm your identity</h2>
                    <br></br>
                    <p>
                      We need to confirm your identity before signing up to the
                      service
                    </p>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="d-flex">
                        <div className="nhsuk-radios">
                          <FormControl fullWidth>
                            <TextField
                              id="surname"
                              name="surname"
                              label="Surname"
                              variant="outlined"
                              value={formik.values.surname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.surname &&
                                Boolean(formik.errors.surname)
                              }
                              helperText={
                                formik.touched.surname && formik.errors.surname
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="nhsuk-radios">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth>
                              <DesktopDatePicker
                                label="Date of birth"
                                value={formik.values.dateOfBirth}
                                onChange={(value) =>
                                  formik.setFieldValue("dateOfBirth", value)
                                }
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                error={
                                  formik.touched.dateOfBirth &&
                                  Boolean(formik.errors.dateOfBirth)
                                }
                                helperText={
                                  formik.touched.dateOfBirth &&
                                  formik.errors.dateOfBirth
                                }
                                format="DD-MM-YYYY"
                              />
                            </FormControl>
                          </LocalizationProvider>
                        </div>
                        <div className="nhsuk-radios">
                          <FormControl fullWidth>
                            <TextField
                              id="activationCode"
                              name="activationCode"
                              label="Activation Code"
                              variant="outlined"
                              value={formik.values.activationCode}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Allow only numeric values
                                if (/^\d*$/.test(value)) {
                                  formik.setFieldValue("activationCode", value);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.activationCode &&
                                Boolean(formik.errors.activationCode)
                              }
                              helperText={
                                formik.touched.activationCode &&
                                formik.errors.activationCode
                              }
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="nhsuk-radios">
                        {errorMessage && (
                          <div className="error-message">{errorMessage}</div>
                        )}
                      </div>
                      <div className="nhsuk-radios">
                        {isValid && (
                          <div className="error-message">{credentials}</div>
                        )}
                      </div>
                      <ConfirmationAccordian />

                      {loadingSlots ? (
                        <div className="loading-dots">
                          <span>Verifying</span>
                          <span className="loading">.</span>
                          <span className="loading">.</span>
                          <span className="loading">.</span>
                        </div>
                      ) : (
                        <button type="submit" className="nhsuk-button">
                          Continue
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAuthenticationForm;
