import React, { useState } from "react";
import "./../../App.css";
import "./../../Assets/css/Instructions.css";
import useBookingApp from "../../hooks/useBookingApp";
import { savePharmacyBooking } from "../../dispatchers/templateSectionDispatchers";
import {
  rescheduleService,
  setIsPatientAuthenticated,
} from "../../dispatchers/consentDispatchers";
import { showError } from "../../Components/ToastedMessage/ToastedMessage";
import { useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";

function GeneralInstrauctions() {
  const { templateSectionState, consentState } = useBookingApp();
  const {
    infectionServay,
    practitioner,
    selectedSlot,
    userData,
    selectedBookingDate,
    selectedBookingTime,
    pharmacyId,
  } = templateSectionState;
  const { serviceName } = infectionServay;
  const {
    isPatientAuthenticated,
    currentServiceId,
    rescheduleBookingModel,
    selectedPatientDateTime,
  } = consentState || {};
  const patientName = `${userData?.firstName || ""} ${userData?.lastName || ""
    }`;
  const [fullName, setFullName] = useState(patientName);

  const navigate = useNavigate();

  const formatBookingDateWithOrdinal = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    // Get ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${date.toLocaleDateString("en-US", {
      weekday: "long",
    })}, ${day}${getOrdinalSuffix(day)} ${date.toLocaleDateString("en-US", {
      month: "long",
    })}`;
  };

  const handleReschedule = () => {
    const rescheduleModel = {
      ClinicalServiceId: currentServiceId,
      RescheduleDate: selectedPatientDateTime,
    };
    rescheduleService(rescheduleModel)
      .then(() => {
        savePharmacyBooking(selectedSlot).then(() => {
          setFullName(rescheduleBookingModel[0]?.patientName);
          setIsPatientAuthenticated(false);
        });
      })
      .catch((error) => {
        showError("Booking could not be updated.", true, false);
      });
  };

  const handleGoBack = () => {
    navigate(`/book-now/${pharmacyId}`);
  };


  return (
    <div className="instruction_main_container">
      <div className="pre-consultation-page">
        <div className="content_page">
          {isPatientAuthenticated && (
            <div className="booking_back_button" onClick={handleGoBack}>
              <GoArrowLeft className="icons-color" />
              <p>Back</p>
            </div>
          )}
          <h2>
            {isPatientAuthenticated ? "Booking Details" : "Booking Confirmed"}
          </h2>
          <div className="confirmation_main_wrapper">
            <div className="content_title">
              <span>Appointment type:</span>
              <span>{serviceName}</span>
            </div>
            <div className="content_title">
              <span>Booking Date:</span>
              <span>
                {`${formatBookingDateWithOrdinal(selectedBookingDate) || ""}`}
              </span>
            </div>
            <div className="content_title">
              <span>Booking Time:</span>
              <span>{`${selectedBookingTime || ""}`}</span>
            </div>
            <div className="content_title">
              <span>Clinician:</span>
              <span>{practitioner}</span>
            </div>
            <div className="content_title">
              <span>Patient:</span>
              {isPatientAuthenticated ? (
                <span>{rescheduleBookingModel[0]?.patientName || ""}</span>
              ) : (
                <span>{fullName || ""}</span>
              )}
            </div>
            <div className="content_title">
              <span>Location:</span>
              <span>{selectedSlot?.pharmacyName}</span>
            </div>
          </div>
          {isPatientAuthenticated ? (
            <div className="booking_details_footer">
              <button
                type="submit"
                className="nhsuk-button reschedule_btn"
                onClick={handleReschedule}
              >
                Reschedule
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
export default GeneralInstrauctions;
