export const saveConsentFormUrl = (serviceId) =>
  `/External/update-clinical-service-from-consent?clinicalServiceId=${serviceId}`;
export const getConsentDataUrl = (searchType, keyword) =>
  `/External/get-consent?searchType=${searchType}&keyword=${keyword}`;
export const savePharmacyFirstConsent = () =>
  "/External/save-pharmacy-first-data";
export const saveRatingUrl = () => "/External/save-rating";
export const GetPharmacyBookingByIdUrl = (pharmacyId, serviceID) =>
  `/PrivateServicesBooking/get-booking-by-pharmacy-id?pharmacyID=${pharmacyId}&serviceID=${serviceID}`;
export const savePharmacyBookingUrl = () =>
  "/PrivateServicesBooking/save-pharmacy-booking";
export const saveServiceDataUrl = () =>
  "/PrivateServicesBooking/save-service-data";
export const GetPrivateServicesByPharmacyIdUrl = (pharmacyId) =>
  `/PrivateServicesBooking/get-pharmacy-default-services-by-pharmacy-id?pharmacyID=${pharmacyId}`;
export const getPharmacyNameByClinicalServiceIdUrl = (clinicalserviceId) =>
  `/External/get-pharmacy-name?clinicalserviceId=${clinicalserviceId}`;
export const authenticatePatientUrl = (clinicalserviceId) =>
  `/PrivateServicesBooking/authenticate-reschedule-patient?clinicalserviceId=${clinicalserviceId}`;
export const rescheduleServiceUrl = () =>
  `/PrivateServicesBooking/reschedule-service`;
export const getTemplateByServiceIdUrl = (
  pharmacyId,
  serviceId,
  clinicalServiceId
) =>
  `/PrivateServicesBooking/get-template-by-id?pharmacyId=${pharmacyId}&serviceId=${serviceId}&clinicalServiceId=${clinicalServiceId}`;
export const saveTemplateUrl = () => `/PrivateServicesBooking/save-template`;
export const cancelAppointmentUrl = (clinicalServiceId) =>
  `/PrivateServicesBooking/cancel-appointment?clinicalServiceId=${clinicalServiceId}`;
export const getPharmacyServiceSettingsUrl = (pharmacyId, serviceID) =>
  `/PrivateServicesBooking/get-service-settings?pharmacyID=${pharmacyId}&serviceID=${serviceID}`;
