import { dispatch } from "../store/store";
import { logError } from "../services/logService";
import {
  setInfectionServay,
  setIsTemplateLoading,
  setUserData,
  setPharmacyIdData,
  addPharmacyFirstData,
  setServiceIdData,
  setIsCompletedData,
  setIsModalOpenData,
  setIsEditingData,
  setIsRadioSelectedData,
  setIsResponseSuccessData,
  setReponseMessageData,
  setIsBookingLoading,
  GetPharmacyBookingByIdData,
  setSelectedSlotData,
  setIsCalendarVisibleData,
  setPractitionerData,
  setIsPractionerVisibleData,
  savePharmacyBookingData,
  setIsBackData,
  saveServiceData,
  setSelectedBookingDateData,
  setSelectedBookingTimeData,
  setIsServicesLoading,
  GetPrivateServicesByPharmacyIdData,
  setPatientTitleData,
  setSelectedBookingSlotData,
  getTemplateByServiceIdData,
  setBookingTemplateData,
  saveTemplateData,
  setAlreadyBookedSlotData,
} from "../features/templateSectionSlice/templateSectionSlice";

const reducerName = "templateSection";

export const setInfectionServayData = (data) => {
  dispatch(setInfectionServay(data));
};

export const setUserInfo = (data) => {
  dispatch(setUserData(data));
};
export const setReponseMessage = (data) => {
  dispatch(setReponseMessageData(data));
};
export const setSelectedSlot = (data) => {
  dispatch(setSelectedSlotData(data));
};
export const setPatientTitle = (data) => {
  dispatch(setPatientTitleData(data));
};
export const setPharmacyId = (id) => {
  dispatch(setPharmacyIdData(id));
};

export const setServiceId = (id) => {
  dispatch(setServiceIdData(id));
};

export const setIsTemplateLoadingData = (isLoading) => {
  dispatch(setIsTemplateLoading(isLoading));
};

export const setBookingLoading = (isLoading) => {
  dispatch(setIsBookingLoading(isLoading));
};

export const setServicesLoading = (isLoading) => {
  dispatch(setIsServicesLoading(isLoading));
};

export const setIsCompleted = (value) => {
  dispatch(setIsCompletedData(value));
};
export const setIsModalOpen = (value) => {
  dispatch(setIsModalOpenData(value));
};
export const setIsEditing = (value) => {
  dispatch(setIsEditingData(value));
};
export const setIsRadioSelected = (value) => {
  dispatch(setIsRadioSelectedData(value));
};
export const setIsResponseSuccess = (value) => {
  dispatch(setIsResponseSuccessData(value));
};
export const setIsCalendarVisible = (value) => {
  dispatch(setIsCalendarVisibleData(value));
};
export const setIsPractionerVisible = (value) => {
  dispatch(setIsPractionerVisibleData(value));
};
export const setPractitioner = (value) => {
  dispatch(setPractitionerData(value));
};
export const setIsBack = (value) => {
  dispatch(setIsBackData(value));
};
export const setSelectedBookingDate = (value) => {
  dispatch(setSelectedBookingDateData(value));
};
export const setSelectedBookingTime = (value) => {
  dispatch(setSelectedBookingTimeData(value));
};
export const setSelectedBookingSlot = (slot) => {
  dispatch(setSelectedBookingSlotData(slot));
};
export const setBookingTemplate = (template) => {
  dispatch(setBookingTemplateData(template));
};
export const setAlreadyBookedSlot = (bookedSlot) => {
  dispatch(setAlreadyBookedSlotData(bookedSlot));
};

export const addPharmacyFirst = async (data) => {
  let result = null;
  try {
    result = await dispatch(addPharmacyFirstData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const GetPharmacyBookingById = async (params) => {
  let result = null;
  try {
    result = await dispatch(GetPharmacyBookingByIdData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const GetPrivateServicesByPharmacyId = async (pharmacyId) => {
  let result = null;
  try {
    result = await dispatch(
      GetPrivateServicesByPharmacyIdData(pharmacyId)
    ).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const savePharmacyBooking = async (data) => {
  let result = null;
  try {
    result = await dispatch(savePharmacyBookingData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const saveService = async (data) => {
  let result = null;
  try {
    result = await dispatch(saveServiceData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const getTemplateByServiceId = async (params) => {
  let result = null;
  try {
    result = await dispatch(getTemplateByServiceIdData(params)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};

export const saveTemplate = async (data) => {
  let result = null;
  try {
    result = await dispatch(saveTemplateData(data)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
