import { dispatch } from "../store/store";
import { logError } from "../services/logService";
import {
  setNhsFormData,
  getConsentData,
  setIsConsentLoading,
  saveConsentFormData,
  setHealthyAdviceData,
  setBookingDateData,
  setIsReschedule,
  setConsentFormData,
  setBookingTimePeriodData,
  setRatingData,
  saveRatingData,
  setCurrentServiceIdData,
  setPatientConsents,
  getPharmacyNameByServiceId,
  authenticatePatientData,
  setPatientConfirmationEmailData,
  setIsPatientAuthenticatedData,
  rescheduleServiceData,
  setRescheduleBookingModelData,
  setSelectedPatientDateTimeData,
  cancelAppointmentData,
  getPharmacyServiceSettingsData,
} from "../features/consentSlice/consentSlice";
const reducerName = "consent";
export const setNhsForm = (data) => {
  dispatch(setNhsFormData(data));
};
export const setHealthyAdvice = (data) => {
  dispatch(setHealthyAdviceData(data));
};
export const setBookingDate = (data) => {
  dispatch(setBookingDateData(data));
};
export const setConsentLoading = (isLoading) => {
  dispatch(setIsConsentLoading(isLoading));
};
export const setRescheduleBooking = (value) => {
  dispatch(setIsReschedule(value));
};
export const setConsentFormModel = (model) => {
  dispatch(setConsentFormData(model));
};
export const setCurrentRatingData = (rating) => {
  dispatch(setRatingData(rating));
};
export const setBookingTimePeriod = (time) => {
  dispatch(setBookingTimePeriodData(time));
};
export const setCurrentServiceId = (serviceId) => {
  dispatch(setCurrentServiceIdData(serviceId));
};
export const setPatientConsentData = (data) => {
  dispatch(setPatientConsents(data));
};
export const setPatientConfirmationEmail = (email) => {
  dispatch(setPatientConfirmationEmailData(email));
};
export const setIsPatientAuthenticated = (isValid) => {
  dispatch(setIsPatientAuthenticatedData(isValid));
};
export const setRescheduleBookingModel = (model) => {
  dispatch(setRescheduleBookingModelData(model));
};
export const setSelectedPatientDateTime = (dateTime) => {
  dispatch(setSelectedPatientDateTimeData(dateTime));
};

export const getAllConsentData = async (param) => {
  let result = [];
  try {
    result = await dispatch(getConsentData(param)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const authenticatePatient = async (param) => {
  let result = [];
  try {
    result = await dispatch(authenticatePatientData(param)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const saveRating = async (data) => {
  let result = [];
  try {
    result = await dispatch(saveRatingData(data)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const saveConsentData = async (serviceId, data) => {
  let result = null;
  try {
    result = await dispatch(saveConsentFormData({ serviceId, data })).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const getPharmacyNameByClinicalServiceId = async (serviceId) => {
  let result = null;
  try {
    result = await dispatch(getPharmacyNameByServiceId(serviceId)).unwrap();
  } catch (error) {
    logError(error);
    result = null;
  }
  return result;
};
export const rescheduleService = async (data) => {
  let result = [];
  try {
    result = await dispatch(rescheduleServiceData(data)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const cancelAppointment = async (clinicalServiceId) => {
  let result = [];
  try {
    result = await dispatch(cancelAppointmentData(clinicalServiceId)).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
export const getPharmacyServiceSettings = async (pharmacyId, serviceID) => {
  let result = [];
  try {
    result = await dispatch(getPharmacyServiceSettingsData({pharmacyId, serviceID})).unwrap();
  } catch (error) {
    result = [];
  }
  return result;
};
